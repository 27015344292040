const state = {
    isHubConnected: false
};

const mutations = {
    SET_IS_HUB_CONNECTED(state, isConnected) {
        state.isHubConnected = isConnected;
    }
};

const actions = {
    setIsHubConnected({ commit }, isConnected) {
        commit('SET_IS_HUB_CONNECTED', isConnected);
    }
};

const getters = {
    isHubConnected: state => state.isHubConnected
};

export default {
    state,
    mutations,
    actions,
    getters
};
export default [
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/chat/index'),
        beforeResolve(routeTo, routeFrom, next) {
            const isUserLoggedIn = true; //todo: store'dan token olduguna emin olunmali mi?
            if (isUserLoggedIn) {
                next({
                    name: 'home'
                })
            }
            else {
                next(); //todo: burasi boyle olmayacak ama simdilik loginimiz de yok. otomatik login olucaz.
            }
        },
    },
    //{
    //    path: '/login',
    //    name: 'login',
    //    component: () => import('../views/pages/account/login.vue'),
    //    meta: {
    //        beforeResolve(routeTo, routeFrom, next) {

    //            if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
    //                const authStore = useAuthStore()
    //                const isUserLoggedIn = authStore.loggedIn

    //                if (isUserLoggedIn) {
    //                    next({
    //                        name: 'home'
    //                    })
    //                } else {
    //                    next()
    //                }

    //            } else {
    //                const fakeAuth = useAuthFakeStore()
    //                const isUserLoggedIn = fakeAuth.loggedIn
    //                // If the user is already logged in
    //                if (isUserLoggedIn) {
    //                    next({
    //                        name: 'home'
    //                    })
    //                } else {
    //                    next()
    //                }

    //            }

    //        },
    //    },
    //},
    //{
    //    path: '/register',
    //    name: 'register',
    //    component: () => import('../views/pages/account/register.vue'),
    //},
    //{
    //    path: '/forgot-password',
    //    name: 'Forgot-password',
    //    component: () => import('../views/pages/account/forgot-password.vue'),
    //},
    //{
    //    path: '/logout',
    //    name: 'logout',
    //    meta: {
    //        authRequired: false,
    //        beforeResolve(routeTo, routeFrom, next) {
    //            if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
    //                const authStore = useAuthStore()
    //                authStore.logOut()
    //                next({
    //                    name: 'login'
    //                })
    //            } else {
    //                const fakeAuth = useAuthFakeStore()
    //                fakeAuth.logout()
    //                next()

    //            }
    //        },
    //    },
    //},
    //{
    //    path: '/lock-screen',
    //    name: 'lock-screen',
    //    component: () => import('../views/pages/account/lock-screen'),
    //}
]
import { createApp, watch } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import store from './store/vuex'; // Vuex store'u import edin
import i18n from './i18n'

const pinia = createPinia();
// const store = createStore();
import "vue-select/dist/vue-select.css";

// import Vuelidate from 'vuelidate';
import BootstrapVueNext from 'bootstrap-vue-next';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import "@/assets/scss/app.scss";

import vSelect from 'vue-select';

import 'emoji-picker-element';

import axios from 'axios';
import VueApexCharts from "vue3-apexcharts";

const app = createApp(App);
app.component('v-select', vSelect);
app.component('VueApexChart', VueApexCharts);

app
    .use(router)
    .use(store)
    .use(pinia)
    .use(i18n)
    .use(BootstrapVueNext)
    .mount('#app');

function enableAxiosResponseInterceptor() {
    let isRefreshing = false;
    let failedQueue = [];

    const processQueue = (error, token = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.config.headers['Authorization'] = `Bearer ${token}`;
                axios(prom.config).then(response => {
                    prom.resolve(response);
                }).catch(err => {
                    prom.reject(err);
                });
            }
        });

        failedQueue = [];
    };

    axios.interceptors.response.use(
        response => response,
        err => {
            const { config, response } = err;
            const originalRequest = config;

            if (response && response.status === 401) {
                if (!isRefreshing) {
                    isRefreshing = true;
                    requestTokenRefresh();
                }

                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject, config: originalRequest });
                });
            }

            return Promise.reject(err);
        }
    );

    watch(
        () => store.getters.token,
        (newToken) => {
            if (newToken) {
                processQueue(null, newToken);
                isRefreshing = false;
            }
        }
    );
}

function requestTokenRefresh() {
    if (store.getters.isIframe)
        window.parent.postMessage({ action: 'pingToken' }, store.getters.tenantUrl);

}

function UiLanguageChangedWatch() {
    watch(
        () => store.getters.language,
        (lng) => {
            if (lng) {
                i18n.global.locale = lng; 
            }
        }
    );
}


enableAxiosResponseInterceptor();
UiLanguageChangedWatch();
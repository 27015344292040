import axios from "axios";
import siriusHub from '../../siriushub'

const state = {
    tenantId: 0,
    isLoggedIn: false,
    branchId: "",
    staffId: "",
    staff: {},
    language: "tr",
    tenantUrl: "",
    token: "",
    isAxiosReady: false,
    isIframe: window.self !== window.top
};

const mutations = {
    SET_TENANT_ID(state, tenantId) {
        state.tenantId = tenantId;
    },
    SET_TENANT_URL(state, tenantUrl) {
        axios.defaults.baseURL = tenantUrl;

        state.tenantUrl = tenantUrl;
    },
    SET_STAFF_ID(state, staffId) {
        state.staffId = staffId;
    },
    SET_STAFF_OBJECT(state, staff) {
        state.staff = staff;
    },
    SET_BRANCH_ID(state, branchId) {
        axios.defaults.headers.common['BranchID'] = branchId;

        state.branchId = branchId;
    },
    SET_TOKEN(state, token) {
        axios.defaults.headers.common['Authorization'] = token;

        state.token = token;
    },
    SET_IS_AXIOS_READY(state, isAxiosReady) {
        state.isAxiosReady = isAxiosReady;
    },
    SET_LANGUAGE(state, lng) {
        state.language = lng;
    }
};

const actions = {
    setTenantId({ commit }, tenantId) {
        commit('SET_TENANT_ID', tenantId);
    },
    setTenantUrl({ commit }, tenantUrl) {
        commit('SET_TENANT_URL', tenantUrl);
    },
    setStaffId({ commit }, staffId) {
        commit('SET_STAFF_ID', staffId);
    },
    setBranchId({ commit }, branchId) {
        commit('SET_BRANCH_ID', branchId);
    },
    setToken({ commit }, token) {
        commit('SET_TOKEN', token);
    },
    axiosInitialize({ commit }, event) {
        var tenantId = event.tid;
        if (tenantId) {
            commit('SET_TENANT_ID', tenantId);
        }

        var staffId = event.sid;
        if (staffId) {
            commit('SET_STAFF_ID', staffId);
        }

        var staffObject = event.sco;
        if (staffObject) {
            commit('SET_STAFF_OBJECT', staffObject);

            if (staffObject.language) {
                commit('SET_LANGUAGE', staffObject.language);
            }
        }

        var tenantUrl = event.url;
        if (tenantUrl) {
            commit('SET_TENANT_URL', tenantUrl);
        }

        var branchId = event.bid;
        if (branchId) {
            commit('SET_BRANCH_ID', branchId);
        }

        var token = event.token;
        if (token && token.indexOf("Bearer") === -1) {
            token = "Bearer " + token;
        }

        if (event.token) {
            commit('SET_TOKEN', token);
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            commit('SET_IS_AXIOS_READY', true);
        }

        siriusHub.init(tenantUrl, tenantId, staffId);
        siriusHub.start();
    }
};

const getters = {
    tenantId: state => state.tenantId,
    tenantUrl: state => state.tenantUrl,
    token: state => state.token,
    branchId: state => state.branchId,
    isAxiosReady: state => state.isAxiosReady,
    isIframe: state => state.isIframe,
    staff: state => state.staff,
    language: state => state.language,
    checkPermission: (state) => (permissionStr) => {
        if (state.staff && state.staff.authorizedPermissionNamesList && state.staff.authorizedPermissionNamesList.indexOf(permissionStr) !== -1) {
            return true;
        } else {
            return false;
        }
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
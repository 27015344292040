import { createStore } from 'vuex';
import Setting from './settings';
import SignalR from './signalr';
import Session from './session';

export default createStore({
    modules: {
        Setting,
        SignalR,
        Session
    }
});

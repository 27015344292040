const state = {
    selectedChannelId: 1,
    selectedAccountsId: [],
    selectedChatId: null,
    selectedChatData: null,
    newMessage: null,
    statusChange: null,
    clientEventHandled: null,
    updatedChatCustomerId: null
};

const mutations = {
    SET_SELECTED_CHANNEL_ID(state, channelId) {
        state.selectedChannelId = channelId;
    },
    SET_SELECTED_ACCOUNTS_ID(state, accountsId) {
        state.selectedAccountsId = accountsId;
    },
    SET_SELECTED_CHAT_ID(state, chatId) {
        state.selectedChatId = chatId;
    },
    SET_SELECTED_CHAT_DATA(state, chatData) {
        state.selectedChatData = chatData;
    },
    SET_NEW_MESSAGE(state, message) {
        state.newMessage = message;
    },
    SET_STATUS_CHANGE(state, statusChange) {
        state.statusChange = statusChange;
    },
    SET_CLIENT_EVENT_HANDLED(state, clientEventHandled) {
        state.clientEventHandled = clientEventHandled;
    },
    SET_CHAT_ARCHIVE_STATE_CHANGED(state, chatArchiveStateChanged) {
        state.chatArchiveStateChanged = chatArchiveStateChanged;
    },
    SET_WHATSAPP_AUTO_RESPOND_MESSAGE_SENT(state, whatsappAutoRespondMessageSent) {
        state.whatsappAutoRespondMessageSent = whatsappAutoRespondMessageSent;
    },
    SET_UPDATED_CHAT_CUSTOMERID(state, updatedChatCustomerId) {
        state.updatedChatCustomerId = updatedChatCustomerId;
    },
};

const actions = {
    setSelectedChannelId({ commit }, channelId) {
        commit('SET_SELECTED_CHANNEL_ID', channelId);
    },
    setSelectedAccountsId({ commit }, accountsId) {
        commit('SET_SELECTED_ACCOUNTS_ID', accountsId);
    },
    setSelectedChatId({ commit }, chatId) {
        commit('SET_SELECTED_CHAT_ID', chatId);
    },
    setSelectedChatData({ commit }, chatData) {
        commit('SET_SELECTED_CHAT_DATA', chatData);
    },
    setNewMessage({ commit }, message) {
        commit('SET_NEW_MESSAGE', message);
    },
    setStatusChange({ commit }, message) {
        commit('SET_STATUS_CHANGE', message);
    },
    setClientEventHandled({ commit }, message) {
        commit('SET_CLIENT_EVENT_HANDLED', message);
    },
    setChatArchiveStateChanged({ commit }, message) {
        commit('SET_CHAT_ARCHIVE_STATE_CHANGED', message);
    },
    setWhatsappAutoRespondMessageSent({ commit }, message) {
        commit('SET_WHATSAPP_AUTO_RESPOND_MESSAGE_SENT', message);
    },
    setUpdatedChatCustomerId({ commit }, message) {
        commit('SET_UPDATED_CHAT_CUSTOMERID', message);
    },
};

const getters = {
    selectedChannelId: state => state.selectedChannelId,
    selectedAccountsId: state => state.selectedAccountsId,
    selectedChatId: state => state.selectedChatId,
    selectedChatData: state => state.selectedChatData,
    newMessage: state => state.newMessage,
    statusChange: state => state.statusChange,
    clientEventHandled: state => state.clientEventHandled,
    chatArchiveStateChanged: state => state.chatArchiveStateChanged,
    whatsappAutoRespondMessageSent: state => state.whatsappAutoRespondMessageSent,
    updatedChatCustomerId: state => state.updatedChatCustomerId
};

export default {
    state,
    mutations,
    actions,
    getters
};
import axios from 'axios';

export default {
    getAllTranslations() {
        return axios.get(process.env.VUE_APP_TRANSLATE_URL, { responseType: 'json' })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return Promise.reject(error);
            });
    }
}

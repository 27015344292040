import { createI18n } from 'vue-i18n';
import translationService from '../src/services/translation.service';
import store from '../src/store/vuex';

const i18nInstance = createI18n({
    locale: store.getters.language,
    fallbackLocale: 'en',
    messages: {}
});

translationService.getAllTranslations()
    .then((response) => {
        for (const lang in response) {
            if (Object.prototype.hasOwnProperty.call(response, lang)) {
                i18nInstance.global.setLocaleMessage(lang, response[lang]);
            }
        }
    })
    .catch(error => {
        console.error(error);
    });

export default i18nInstance;
